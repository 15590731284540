import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImg from 'gatsby-background-image'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { useIntl } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'

// Components
import Pagina from '../components/Pagina'
import FloatingItem from '../components/FloatingItem/floating-icon'

// CSS, SCSS
import './styles/assessoria-ambiental.scss'

// Assets
import icone1 from '../images/assessoria-ambiental/icons/gestao-documentos.svg'
import icone2 from '../images/assessoria-ambiental/icons/avaliacao.svg'
import icone3 from '../images/assessoria-ambiental/icons/assessorias.svg'

const Paralax = ({ paralax, paralaxMobile }) => {
  const intl = useIntl()

  const image = getImage(paralax)
  const bgImage = convertToBgImage(image)
  const imageMobile = getImage(paralaxMobile)
  const bgImageMobile = convertToBgImage(imageMobile)
  return <>
    <div>
      <BackgroundImg
        {...bgImage}
        Tag="div"
        className="paralaxImage d-lg-block d-none"
      >
        <div className="container h-100 d-flex justify-content-start ">
          <div className=" h-100 d-flex align-items-center">
            <div className="w-100">
              <h1 className="pb-4 text-white">{parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.paralax' }))}</h1>
            </div>
          </div>
        </div>
      </BackgroundImg>
      <BackgroundImg
        {...bgImageMobile}
        Tag="div"
        className="paralaxImage d-lg-none d-block"
      >
        <div className="container h-100 pt-5">
          <div className=" h-100 d-flex align-items-center">
            <div className="w-100 pl-5 pt-5">
              <h1 className="text-white">{parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.paralax' }))}</h1>
            </div>
          </div>
        </div>
      </BackgroundImg>
      <div className="gradient-blue-to-green"></div>
    </div>
  </>
}

const MoldeIcones = props => (
  <>
    <div className='d-flex mb-4'>
      <div className='icon justify-content-center align-items-center'>
        <img src={props.icon} className={props.width} />
      </div>
      <p className='d-flex align-items-center line-height-normal ml-3 m-0'>{props.text}</p>
    </div>
  </>
)

const GestaoTotalResiduos = ({ data }) => {
  const intl = useIntl()

  return <>
    <Pagina pagina={data.pagina} />
    <FloatingItem link='https://api.whatsapp.com/send?phone=5508007010088&text=Olá, quero solicitar uma visita de um consultor.'/>
    <section className="full m-0 p-0">
      <Paralax paralax={data.paralax.childImageSharp.gatsbyImageData} paralaxMobile={data.paralaxMobile.childImageSharp.gatsbyImageData} />
      <div className="container">
        <div className="row">
          <div className='col-lg-6 col-12 py-5'>
            <h5 className='font-size-12 line-height-normal letter-space-0'>{parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.p1.p1' }))} <span className='text-lwart-lightblue2 font-Spinnaker font-size-12'>{parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.p1.span1' }))}</span> {parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.p1.p2' }))}
            </h5>
            <h5 className='font-size-12 py-3 letter-space-0'>{parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.p2.p1' }))}
              <span className='text-lwart-lightblue2 font-Spinnaker font-size-12'>{parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.p2.span1' }))}</span>
            </h5>
            <h5 className='font-size-12 py-3 letter-space-0'>{parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.p3.p1' }))} <span className='text-lwart-lightblue2 font-Spinnaker font-size-12'>{parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.p3.span1' }))}</span>
              {parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.p3.p2' }))}
            </h5>
            <a href='https://api.whatsapp.com/send?phone=5508007010088' target="_blank" rel="noopener noreferrer" className='font-Spinnaker font-size-12'><u>{parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.link' }))}</u></a>
          </div>
          <div className='col-lg-6 col-12 p-5'>
            <MoldeIcones icon={icone1} width='w-50' text={parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.icones.garantia' }))} />
            <MoldeIcones icon={icone2} width='w-60' text={parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.icones.avaliacao' }))} />
            <MoldeIcones icon={icone3} width='w-65' text={parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.icones.adequacao' }))} />
          </div>
        </div>
      </div>
      {/* <div className="container d-block d-lg-none">
        <div className='py-5'>
          <h5 className='font-size-12 line-height-normal letter-space-0'>{parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.p1.p1' }))} <span className='text-lwart-lightblue2 font-Spinnaker font-size-12'>{parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.p1.span1' }))}</span> {parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.p1.p2' }))}
          </h5>
          <h5 className='font-size-12 py-3 letter-space-0'>{parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.p2.p1' }))}
            <span className='text-lwart-lightblue2 font-Spinnaker font-size-12'>{parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.p2.span1' }))}</span>
          </h5>
          <h5 className='font-size-12 py-3 letter-space-0'>{parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.p3.p1' }))} <span className='text-lwart-lightblue2 font-Spinnaker font-size-12'>{parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.p3.span1' }))}</span>
            {parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.p3.p2' }))}
          </h5>
          <a href='https://api.whatsapp.com/send?phone=08007010088' target="_blank" rel="noopener noreferrer" className='font-Spinnaker font-size-12'><u>{parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.link' }))}</u></a>
        </div>
        <div className='pb-5'>
          <MoldeIcones icon={icone1} width='w-50' text={parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.icones.garantia' }))} />
          <MoldeIcones icon={icone2} width='w-60' text={parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.icones.garantia' }))} />
          <MoldeIcones icon={icone3} width='w-65' text={parse(intl.formatMessage({ id: 'paginas.gestao_total_de_residuos.gestao_total_de_residuos_main.icones.garantia' }))} />
        </div>
      </div> */}
    </section>
    <div className="gradient-blue-to-green"></div>
  </>
}

export default GestaoTotalResiduos

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/gestao-total-de-residuos/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  paralax: file(relativePath: {eq: "gestao-total-de-residuos/banner-gestao-residuos.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  paralaxMobile: file(relativePath: {eq: "gestao-total-de-residuos/gestao-total-residuos.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
